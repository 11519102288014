import {LeadService} from './../../shared/services/lead.service';
import {EventEmitter, Injectable, Injector} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {forkJoin, Observable} from 'rxjs';
import {ProductService} from '@app/shared/services/product.service';
import {map} from 'rxjs/operators';
import {AppConsts} from '@shared/AppConsts';
import * as moment from 'moment';
import {PolicyService} from '@app/shared/services/policy.service';
import {ProductFactory} from '@app/shared/FactoryResources/ProductFactory/IIncompleteProduct';
import {IOccupationPremium, Product} from '@app/shared/FactoryResources/ProductFactory/Product';
import {QuirkService} from '../../shared/services/quirk.service';
import {
    AnonymousUserDetail,
    BannerStage,
    BenefitModule,
    BenficiaryModule,
    BenificeryModule,
    CommissionModule,
    CreateOrUpdatePolicyDtoOutpuModel,
    CreateOrUpdatePolicyInputModule,
    CreateQuote,
    defaultOccupationClass,
    GetAllQuotes,
    GetMaxSumAssuredModule,
    GetQuestionariesModule,
    IBannerStage,
    IQuircAnalysisAfterSubmitting,
    ISubmittedQuoteDto,
    ITemplateEmailDto,
    IUserAdditionalDisclosure,
    MedicalInsuranceAssessment,
    PersonModule,
    PoliciesModule,
    PolicyModule,
    PremiumPatternModule,
    ProductListModule,
    ProductQuote,
    ProductSummary,
    QuoteModule,
    RateTableModule,
    RiderProductQuote,
    SubmitAnswerAndGetUpdatedQuestions,
    SubmitQuestionnaireModule,
    SynopsisDetails
} from '../../shared/models/elevate-data-models';
import {HttpMethodBaseService} from '@app/shared/services/HttpMethodsBase/httpmethods-base';
import {AssociatedProduct} from '@app/shared/FactoryResources/AssociatedProductFactory/associated-product';
import {getCreatePolicyResult} from 'assets/data/quick-quote-data-models';
import {IdpCLaimsServiceProxy} from '@shared/service-proxies/service-proxies';
import {FormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {IdentityAuthProviderService} from '@app/shared/common/auth/identity-auth-provider.service';

@Injectable()
export class MyCommandCenterService extends AppComponentBase {
    readonly CEDANT_ID = abp.setting.values[AppConsts.KeyVaultSecrets.cedantId];
    productQuoteDictionary = new Map();
    private _productService: ProductService;

    public get productService(): ProductService {
        if (!this._productService) {
            this._productService = this.injector.get(ProductService);
        }
        return this._productService;
    }

    private _policyService: PolicyService;
    public get policyService(): PolicyService {
        if (!this._policyService) {
            this._policyService = this.injector.get(PolicyService);
        }
        return this._policyService;
    }

    private _quirkService: QuirkService;
    public get quirkService(): QuirkService {
        if (!this._quirkService) {
            this._quirkService = this.injector.get(QuirkService);
        }
        return this._quirkService;
    }

    private _claimService: IdpCLaimsServiceProxy;
    public get claimService(): IdpCLaimsServiceProxy {
        if (!this._claimService) {
            this._claimService = this.injector.get(IdpCLaimsServiceProxy);
        }
        return this._claimService;
    }

    private _leadService: LeadService;
    public get leadService(): LeadService {
        if (!this._leadService) {
            this._leadService = this.injector.get(LeadService);
        }
        return this._leadService;
    }

    private _authservice: IdentityAuthProviderService;
    public get authservice(): IdentityAuthProviderService {
        if (!this._authservice) {
            this._authservice = this.injector.get(IdentityAuthProviderService);
        }
        return this._authservice;
    }

    public vm: {
        sumAssuredExtQuote: string,
        productSyncedOrNot: boolean,
        producSpinnerEmitter: EventEmitter<boolean>,
        productEmitter: EventEmitter<boolean>,
        productDetailsFromFactory: Array<Product>,
        questionsTobeStore: Array<GetQuestionariesModule.IQuestion>,
        currentPolicyId: string,
        CustomerReferenceid: string,
        CedantId: string,
        userDetails: AnonymousUserDetail,
        initialUserDetails: AnonymousUserDetail,
        entityId: string,
        quoteId: string,
        productDetails: Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>,
        quotedProductDetails: Array<ProductListModule.ISelectedProductDetails>,
        conditionliststore: Map<any, any>,
        selectedProduct: Array<ProductListModule.ISelectedProductDetails>,
        medicalStepsValidater: Map<any, any>,
        additionalInfoStepsValidater: Map<any, any>,
        productSummary: Array<ProductSummary>,
        quircAfterSubmittingAnalysis: Array<IQuircAnalysisAfterSubmitting>,
        currentProductOccupationMuliplier: IOccupationPremium,
        isImprovingQuoteNeccesary: boolean,
        isReferred: boolean,
        show7DayPopups: boolean,
        isBusinessAssuranceQuote: boolean,
        isPolicyHolderNotInsuredLife: boolean,
        isMinimumBenefitNotMet: boolean,
        submitQuestionaireResult: any,
        isQuickQuote: boolean,
        coverReason?: string | null,
        businessAssuranceType?: string | null,
        policyHolderIsInsuredLife?: string | null,
        userAdditionalDisclosures: IUserAdditionalDisclosure[];
        quotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult,
            currentValues: Array<{
                productUid: string,
                sumAssured: number,
                premium: number
            }>
        }>,
        acceptedQuotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult
        }>,
        productExclusionMap: Array<{
            descriptionCategory: string,
            productCategory: string,
            exclusions: Array<string>,
            fullDescriptionCategory: string
        }>,
        riderProductAdded: {
            quoteUid: string,
            mainProductUid: string,
            product: AssociatedProduct
        }
        riderProductRemoved: {
            quoteUid: string,
            mainProductUid: string,
            product: AssociatedProduct
        },
        needsAssessmentSumAssuredLevels: Array<QuoteModule.INeedsAssessmentSumAssuredLevel>,
        brokerGuid: string,
        isBrokerAssignedToQuote: boolean,
        insuranceAssessment: MedicalInsuranceAssessment,
        lifeInsuredPolicies: Array<PoliciesModule.IPolicy>
    } = {
            sumAssuredExtQuote: '',
            productSyncedOrNot: false,
            producSpinnerEmitter: new EventEmitter<boolean>(),
            productEmitter: new EventEmitter<boolean>(),
            productDetailsFromFactory: new Array<Product>(),
            questionsTobeStore: new Array<GetQuestionariesModule.IQuestion>(),
            currentPolicyId: '',
            CustomerReferenceid: '',
            CedantId: abp.setting.values[AppConsts.KeyVaultSecrets.cedantId],
            userDetails: new AnonymousUserDetail(),
            initialUserDetails: new AnonymousUserDetail(),
            entityId: '',
            quoteId: '',
            productDetails: new Array<{ productSectionName: string, products: Array<ProductListModule.IProductListModel> }>(),
            quotedProductDetails: new Array<ProductListModule.ISelectedProductDetails>(),
            conditionliststore: new Map(),
            selectedProduct: new Array<ProductListModule.ISelectedProductDetails>(),
            medicalStepsValidater: new Map(),
            additionalInfoStepsValidater: new Map(),
            productSummary: Array<ProductSummary>(),
            quircAfterSubmittingAnalysis: Array<IQuircAnalysisAfterSubmitting>(),
            isImprovingQuoteNeccesary: false,
            isReferred: false,
            show7DayPopups: false,
            isBusinessAssuranceQuote: false,
            isPolicyHolderNotInsuredLife: false,
            isMinimumBenefitNotMet: false,
            submitQuestionaireResult: null,
            isQuickQuote: false,
            quotePolicyCouples: [],
            acceptedQuotePolicyCouples: [],
            productExclusionMap: [],
            riderProductAdded: null,
            riderProductRemoved: null,
            brokerGuid: '',
            isBrokerAssignedToQuote: true,
            needsAssessmentSumAssuredLevels: [],
            insuranceAssessment: new MedicalInsuranceAssessment(),
            lifeInsuredPolicies: [],
            userAdditionalDisclosures: [],
            currentProductOccupationMuliplier: {
                occupation: ''
            },
        };

    public paymentObject = {
        productDetails: new Array<SynopsisDetails>(),
        beneficiaryDetails: new Array<SynopsisDetails>(),
        bankingDetails: new Array<SynopsisDetails>()
    };

    stepperFormGroup: FormGroup = new FormGroup({});

    constructor(
        private injector: Injector,
        private httpMethodBaseService: HttpMethodBaseService
    ) {
        super(injector);
    }

    initCommandCenterVMRESET() {
        this.vm.quotedProductDetails = [];
        this.vm.questionsTobeStore = [];
        this.vm.quoteId = '';
        this.vm.currentPolicyId = '';
        this.vm.selectedProduct = [];
        this.vm.productSummary = [];
        this.vm.isReferred = false;
        this.vm.show7DayPopups = false;
        this.vm.submitQuestionaireResult = {};
        this.vm.quotePolicyCouples = [];
        this.vm.acceptedQuotePolicyCouples = [];
        this.vm.isMinimumBenefitNotMet = false;
        this.vm.medicalStepsValidater = new Map();
        this.vm.productExclusionMap = [];
        this.vm.needsAssessmentSumAssuredLevels = [];
        this.vm.productDetailsFromFactory.forEach(x => {
            x.CurrentBenefitPattern = undefined;
            x.CurrentPremiumPattern = undefined;
            x.IsProductQuoted = false;
            x.currentRateTable = undefined;
            x.currentPremiumValue = undefined;
            x.CurrentAssuredValue = this.getDeepCopied(x.MinBenefitAmount);
            if (!x.AssociatedProducts) { return; }
            x.AssociatedProducts.forEach(item => {
                item.CurrentBenefitPattern = undefined;
                item.CurrentPremiumPattern = undefined;
                item.IsProductQuoted = item.RequirementType === AppConsts.requirementTyoe.Selectable ? false : true;
                item.currentRateTable = undefined;
                item.CurrentAssuredValue = this.getDeepCopied(item.MinBenefitAmount);
                item.currentPremiumValue = undefined;
            });
        });
    }

    async getMaxSumAssured(productUid: string, maxBenefitAmount: number, options: { mainProductCurrentSumAssured?: number, isAccelerator?: boolean,
        skipExistingCover?: boolean, productSubCategory?: string, mainProductCheck?: boolean } = {}): Promise<number> {
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(q => q.MendixProductUID === productUid);
        const self = this;
        let maxSumAssuredInput: GetMaxSumAssuredModule.GetMaxSumAssuredInput;
        let isBrokerAdmin = this.permission.hasBrokerAdminRole();

        if (!this.vm.isQuickQuote) {
            const policyInput = {
                AnsweredQuestions: [],
                CedantId: this.vm.CedantId,
                CustomerReferenceid: this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: this.vm.currentPolicyId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1'
            };

            const policyResult = await this.quirkService.createOrUpdatePolicy(policyInput, true).toPromise();
            const policyReason = getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.PolicyReason);

            maxSumAssuredInput = {
                age: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.Age).Value,
                existingCover: getExistingCover(options.skipExistingCover, isBrokerAdmin, policyResult),
                grossMonthlyIncome: this.vm.userDetails.confirmedGrossIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                houseHoldIncome: +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.HouseHoldIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                isSelfEmployed: getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true,
                netMonthlyIncome: this.vm.userDetails.confirmedNetIncome || +getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.NetMonthlyIncome, AppConsts.QuirckExtQuestionId.GrossMonthlyIncome).Value,
                employmentCategory: getOccupationSpecific(getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.IsSelfEmployed).Value === '0' ? false : true, getValueOrDefault(policyResult.PolicyDetail.AnsweredQuestions, AppConsts.QuirckExtQuestionId.EmploymentCategory).Text),
                needsAnalysis: getNeedsAssessmentStatus(this.vm.userDetails, false, isBrokerAdmin, policyResult.PolicyDetail.AnsweredQuestions),
                policyReason: policyReason.AnswerText || policyReason.Text,
                productCategory: quircProduct.QuircProfileName,
                isAccelerator: options.isAccelerator,
                mainProductCurrentSumAssured: options.mainProductCurrentSumAssured,
                maxBenefitAmount: maxBenefitAmount
            };
        } else {
            maxSumAssuredInput = {
                age: this.vm.userDetails.age,
                existingCover: getExistingCover(options.skipExistingCover, isBrokerAdmin),
                grossMonthlyIncome: this.vm.isBusinessAssuranceQuote ? 1000000 : +this.vm.userDetails.grossSalary,
                houseHoldIncome: this.vm.isBusinessAssuranceQuote ? 1000000 : Math.max(1.2 * +this.vm.userDetails.grossSalary, 10000),
                isSelfEmployed: true,
                netMonthlyIncome: this.vm.isBusinessAssuranceQuote ? 1000000 : this.vm.userDetails.netIncome || 0.8 * +this.vm.userDetails.grossSalary,
                employmentCategory: getOccupationSpecific(false, this.vm.userDetails.occupation),
                needsAnalysis: getNeedsAssessmentStatus(this.vm.userDetails, true, false),
                policyReason: AppConsts.PolicyReason.FamilyResponsibility,
                productCategory: quircProduct.QuircProfileName,
                isAccelerator: options.isAccelerator,
                mainProductCurrentSumAssured: options.mainProductCurrentSumAssured,
                maxBenefitAmount: maxBenefitAmount
            };
        }

        const sumAssuredResult = await this.productService.getMaxAndMinSummAssured(maxSumAssuredInput).toPromise();
        return sumAssuredResult;

        function getExistingCover(skipExistingCover: boolean, isBrokerAdmin: boolean, policyResult: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto = null): {
            existingOther: number,
            currentElevate: number,
            existingElevate: number,
            productSubCategory: string,
            mainProductCheck: boolean
        } {
            if (skipExistingCover) {
                return {
                    currentElevate: 0,
                    existingElevate: 0,
                    existingOther: 0,
                    productSubCategory: '',
                    mainProductCheck: false
                };
            }

            let existingOther: number;

            if (policyResult) {
                existingOther = getExistingOtherCover(quircProduct.AggregationCategory, policyResult);
            } else {
                existingOther = 0;
            }

            return {
                existingOther,
                currentElevate: getElevateCover(quircProduct.AggregationCategory, self.vm.quotePolicyCouples, productUid),
                existingElevate: isBrokerAdmin ? 0 : getElevateCover(quircProduct.AggregationCategory, self.vm.acceptedQuotePolicyCouples),
                productSubCategory: options.productSubCategory,
                mainProductCheck: options.mainProductCheck
            };
        }

        function getNeedsAssessmentStatus(userDetails: AnonymousUserDetail, skipCreditCheck: boolean, isBrokerAdmin: boolean, answeredQuestions: Array<CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion> = null): string {
            const covertYesNoQuestionToBool = (question: CreateOrUpdatePolicyDtoOutpuModel.IAnsweredQuestion): boolean => {
                return question && (question.AnswerText === 'Yes' || question.Text === 'Yes');
            };

            const getNeedsAssessmentStringValue = (needsAssessment: boolean, creditCheck: boolean, proofOfIncome: boolean, isBrokerAdmin: boolean): string => {

                if (isBrokerAdmin) {
                    return AppConsts.NeedsAnalysis.Full;
                }

                if (needsAssessment) {

                    if (proofOfIncome && (creditCheck || skipCreditCheck)) {
                        return AppConsts.NeedsAnalysis.Full;
                    }

                    return AppConsts.NeedsAnalysis.Partial;
                }

                return AppConsts.NeedsAnalysis.None;
            };

            if (answeredQuestions) {
                const creditCheckQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndCreditCheck);
                const proofOfIncomeQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndIncomeConfirmation);
                const needsAssessmentQuestion = answeredQuestions.find(q => q.ExternalReferenceId === AppConsts.QuirckExtQuestionId.DndNaIndicator);

                if (creditCheckQuestion && proofOfIncomeQuestion && needsAssessmentQuestion) {
                    const creditCheckAnswer = covertYesNoQuestionToBool(creditCheckQuestion);
                    const proofOfIncomeAnswer = covertYesNoQuestionToBool(proofOfIncomeQuestion);
                    const needsAssessmentAnswer = covertYesNoQuestionToBool(needsAssessmentQuestion);

                    return getNeedsAssessmentStringValue(needsAssessmentAnswer, creditCheckAnswer, proofOfIncomeAnswer, isBrokerAdmin);
                }
            }

            return getNeedsAssessmentStringValue(userDetails.needsAssessment, userDetails.creditCheck, userDetails.proofOfIncome, isBrokerAdmin);
        }

        function getElevateCover(
            aggregationCategory: string,
            quotePolicyCouples: Array<{
                quote: QuoteModule.IQuoteDetails;
                policy: PolicyModule.IPolicyListResult;
            }>,
            // If product GUID is included, that product will not be included in the calculation
            productGuid: string = null
        ): number {
            if (!aggregationCategory) {
                return 0;
            }

            const quircProductsInCategory = self.vm.quircAfterSubmittingAnalysis
                .filter(q =>
                    q.AggregationCategory != null &&
                    q.AggregationCategory === aggregationCategory &&
                    q.MendixProductUID !== productGuid);

            if (quircProductsInCategory.length === 0) {
                return 0;
            }

            const invalidQuotesFromPolicies = self.vm.lifeInsuredPolicies.filter(x => x.policyStatus.toLowerCase().indexOf('lapsed') >= 0 || x.policyLapsed).map(x => x.quoteGuid);

            const productsQuoted: Array<QuoteModule.IProductQuoted> = quotePolicyCouples
                .filter(q => q.quote.ProductQuoteds && Array.isArray(q.quote.ProductQuoteds) && !invalidQuotesFromPolicies.includes(q.quote.UID))
                .map(q => q.quote.ProductQuoteds)
                .customFlat();

            const quircMendixProductMapping: Array<{
                mendix: QuoteModule.IProductQuoted,
                quirc: IQuircAnalysisAfterSubmitting
            }> = [];

            for (const quircProductInCategory of quircProductsInCategory) {
                const productsQuotedInCategory = productsQuoted.filter(q => q.ProductConfig.UID === quircProductInCategory.MendixProductUID);

                if (!productsQuotedInCategory || productsQuotedInCategory.length === 0) {
                    continue;
                }

                quircMendixProductMapping.push(...productsQuotedInCategory.map(p => ({
                    mendix: p,
                    quirc: quircProductInCategory
                })));
            }

            const quotedAssuredValue = quircMendixProductMapping
                .map(q => q.quirc.MendixProductDisplayName === 'Family-Health Severe Illness Protection - Accelerator' ? q.mendix.AssuredValue * 12 * 10 : q.mendix.AssuredValue)
                .reduce((acc, cur) => acc + cur, 0);

            return quotedAssuredValue;
        }

        function getExistingOtherCover(aggregationCategory: string, updatePolicyResult: CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto): number {
            const extIdAggregationCategoryMap = {
                [AppConsts.AggregationCategories.lifeProtection]: AppConsts.QuirckExtQuestionId.ExistingLifeCover,
                [AppConsts.AggregationCategories.disabilityProtection]: AppConsts.QuirckExtQuestionId.ExistingIncomeProtectionCover,
                [AppConsts.AggregationCategories.impairmentProtection]: AppConsts.QuirckExtQuestionId.ExistingImpairmentCover,
                [AppConsts.AggregationCategories.severeIllnessProtection]: AppConsts.QuirckExtQuestionId.ExistingSevereIllnessCover,
                [AppConsts.AggregationCategories.disabilityProtectionExtender]: AppConsts.QuirckExtQuestionId.ExistingIncomeProtectionCover,
            };

            const extId = extIdAggregationCategoryMap[aggregationCategory];

            if (!extId) {
                return 0;
            }

            return +getValueOrDefault(updatePolicyResult.PolicyDetail.AnsweredQuestions, extId).Value || 0;
        }

        function getValueOrDefault(res, extid, fallbackExtId: string = null) {
            const question = res.find(x => x.ExternalReferenceId === extid);

            if (question) {
                return question;
            }

            if (fallbackExtId) {
                return getValueOrDefault(res, fallbackExtId);
            }

            return '';
        }

        function getOccupationSpecific(isSelfEmp, valueGiven: string) {
            if (isSelfEmp) {
                return AppConsts.Occupations.SelfEmployed;
            }

            if (valueGiven.toLowerCase().indexOf('student') >= 0) {
                return AppConsts.Occupations.Student;
            }

            if (valueGiven.toLowerCase().indexOf('wife') >= 0 || valueGiven.toLowerCase().indexOf('husband') >= 0) {
                return AppConsts.Occupations.HouseWifeOrHouseHusband;
            }

            if (valueGiven.toLowerCase().indexOf('unemployed') >= 0) {
                return AppConsts.Occupations.Unemployed;
            }
            return AppConsts.Occupations.Employed;
        }
    }

    async getBannerContentFromUrl(url: string): Promise<BannerStage[]> {
        const data = await this.httpMethodBaseService.getAsync<IBannerStage[]>(url);
        return data.map(rec => new BannerStage(rec));
    }

    createOrEditRider(data: RiderProductQuote, quoteId, productQuoteID, riderQuotedId): Observable<any> {
        return this._productService.createOrUpdateRiderProduct(data, quoteId, productQuoteID, riderQuotedId)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails: BenficiaryModule.IBeneficiaryModel): Observable<string> {
        return this.productService.createOrUpdateBeneficiaryAndGetEntityId(beneficiaryDetails)
            .pipe(map(res => {
                return res;
            }));
    }

    createBeneficieryQuirk(beneficiaryForQuote: BenificeryModule.IBeneficiaryForQuote[], productQuotedId: string, quoteId: string): Observable<boolean> {
        return this.productService.addBeneficeryToQuote(productQuotedId, quoteId, beneficiaryForQuote)
            .pipe(map(res => {
                return true;
            }));
    }

    createInclusiveSet(quoteId): Observable<boolean> {
        return this.productService.addNonIndividualExclusionSet(quoteId)
            .pipe(map(res => {
                return true;
            }));
    }

    getAQuote(productQuotedId: string, benefitPatternId: string, premiumPatternId: string, quoteId: string): Observable<Array<QuoteModule.IProductQuoted>> {
        const productQuoteId = this.productQuoteDictionary.get(quoteId);
        let productQuoteSearchString = quoteId + productQuoteId + benefitPatternId + premiumPatternId;
        //let dataDictionary = this.productQuoteDictionary.get(productQuoteSearchString);
        //if (dataDictionary) {
        //    return of(dataDictionary);
        //}

        let response = this.productService.getAQuote(quoteId)
            .pipe(map(res => {
                if (res.ProductQuoteds) {
                    let keyValue = res.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].ProductConfig.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].BenefitPattern.UID + res.ProductQuoteds[res.ProductQuoteds.length - 1].PremiumPattern.UID;
                    this.productQuoteDictionary.set(productQuoteSearchString, res.ProductQuoteds[res.ProductQuoteds.length - 1]);
                    return res.ProductQuoteds;
                }
            }));
        return response;
    }

    getQuestionByExtId(item): GetQuestionariesModule.IQuestion[] {
        return this.quirkService.getQuestionByExtReferenceId(item);
    }

    getProductList(): Observable<ProductListModule.IProductListModel[]> {
        return this.productService.getProductList()
            .pipe(map((res: ProductListModule.IProductListModel[]) => {
                return res.getParsedMainAndRiderProduct();
            }));
    }

    getBenifitPatterns(uid: string[]): Observable<BenefitModule.IBenefitModel[]> {
        return this.productService.getBenefitPatterSetForMultipleUid(uid)
            .pipe(map(res => {
                return res;
            }));
    }

    getPremiumPatterns(uid: string[]): Observable<PremiumPatternModule.IPremiumPatternModel[]> {
        return this.productService.getPremiumPatterSetForMultipleUid(uid)
            .pipe(map(res => {
                return res;
            }));
    }


    submitBrokerAdminQuote(submittedQuote: ISubmittedQuoteDto, templateEmail: ITemplateEmailDto): Observable<boolean> {
        return this.productService.submitAdminQuote(submittedQuote, templateEmail)
            .pipe(map(res => {
                return res;
            }));
    }


    getQuestionForSection(sectionName, entityId, policyId, isCached = true): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        return this.quirkService.getQuestionForStepper(sectionName, null, this.vm.entityId, policyId, [], isCached)
            .pipe(map(res => {
                return res;
            }));
    }

    submitQuestionAnswers(section: GetQuestionariesModule.ISection, policyRefId = this.vm.currentPolicyId, isCached = false): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        let submitQuestions: SubmitAnswerAndGetUpdatedQuestions;
        submitQuestions = {
            customerReferenceid: this.vm.entityId,
            policyReferenceId: policyRefId,
            sections: section
        };
        return this.quirkService.submitProcessQuestionaryAndProvideAsperDictonaryMapping(submitQuestions, this.vm.userDetails, isCached)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdateApplicant(emailId: string, firstName: string, surname: string, dob: Date, callBackToCreatePolicy) {
        let self = this;
        this.initCommandCenterVMRESET();
        this.quirkService.createOrUpdateApplicant({
            CedantId: this.CEDANT_ID,
            Customer: {
                DateOfBirth: dob,
                EmailAddress: getHashed(emailId, 1),
                ExternalReference: this.vm.entityId,
                FirstName: getHashed(firstName, 1),
                NationalID: getHashed(self.vm.userDetails.identityNumber, 7),
                Surname: getHashed(surname, 1)
            },
            ShowErrorDetails: '1',
        }).subscribe(res => {
            callBackToCreatePolicy();
        }, (err) => {
            abp.ui.clearBusy();
        });


        function getHashed(value: string, countToKeep: number) {
            return value.substr(0, countToKeep).padEnd(value.length, '#');
        }
    }

    getQuotePolicyCouples(quotes: Array<QuoteModule.IQuoteDetails>, policies: Array<PolicyModule.IPolicyListResult>): Array<{
        quote: QuoteModule.IQuoteDetails,
        policy: PolicyModule.IPolicyListResult,
        currentValues: Array<{
            productUid: string
            premium: number,
            sumAssured: number
        }>
    }> {
        const quotePolicyCouples: Array<{
            quote: QuoteModule.IQuoteDetails,
            policy: PolicyModule.IPolicyListResult,
            currentValues: Array<{
                productUid: string,
                premium: number,
                sumAssured: number
            }>
        }> = [];

        for (const quote of quotes) {
            let currentValues: Array<{
                productUid: string
                premium: number,
                sumAssured: number
            }> = [];

            if (quote.ProductQuoteds) {
                currentValues = quote.ProductQuoteds
                    .filter(p => p.PremiumValue > 0)
                    .map(p => ({
                        productUid: p.ProductConfig.UID,
                        premium: p.PremiumValue,
                        sumAssured: p.AssuredValue
                    }));
            }


            quotePolicyCouples.push({
                quote: quote,
                policy: policies.find(p => p.ExternalReferenceId === quote.UID),
                currentValues
            });
        }

        return quotePolicyCouples;
    }

    updateQuoteInPolicyCouple(quote: QuoteModule.IQuoteDetails): void {
        const couple = this.vm.quotePolicyCouples.find(c => c.quote.UID === quote.UID);
        couple.quote = quote;

        if (quote.ProductQuoteds) {
            couple.currentValues = quote.ProductQuoteds.map(p => ({
                productUid: p.ProductConfig.UID,
                premium: p.PremiumValue,
                sumAssured: p.AssuredValue
            }));
        }
    }

    updateCurrentValueInQuotePolicyCouple(quoteUid: string, currentValue: { productUid: string, premium: number, sumAssured: number }): void {
        const couple = this.vm.quotePolicyCouples.find(c => c.quote.UID === quoteUid);
        const existingSliderValue = couple.currentValues.find(c => c.productUid === currentValue.productUid);

        if (existingSliderValue) {
            existingSliderValue.premium = currentValue.premium;
            existingSliderValue.sumAssured = currentValue.sumAssured;
        } else {
            couple.currentValues.push(currentValue);
        }
    }

    getOpenQuoteIdFromPolicyCouples(): string {
        for (const couple of this.vm.quotePolicyCouples) {
            if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                return couple.quote.UID;
            }
        }

        return null;
    }

    getQuoteIdForProductFromPolicyCouples(productUid: string): string {
        for (const couple of this.vm.quotePolicyCouples) {
            if (!couple.quote.ProductQuoteds) {
                continue;
            }

            const product = couple.quote.ProductQuoteds.find(q => q.ProductConfig.UID === productUid);

            if (product) {
                return couple.quote.UID;
            }
        }

        return null;
    }

    clearLoadings(): void {
        this.vm.quircAfterSubmittingAnalysis.forEach(x => { x.sumPercentageLoading = 0; x.sumUnitLoading = 0; });
    }

    setLoadings(): void {
        let res = this.vm.submitQuestionaireResult.Products.map(x => ({
            quircProfileName: x.ProfileName,
            decisionName: x.DecisionName,
            sumUnitLoading: x.Premiums.reduce((a, b) => a + b.UnitLoading, 0),
            sumPercentageLoading: x.Premiums.reduce((a, b) => a + b.PercentageLoading, 0),
            productReferences: x.ProductReferences
        }));

        this.clearLoadings();

        res.forEach(item => {
            let product = this.vm.quircAfterSubmittingAnalysis.find(x => x.QuircProfileName === item.quircProfileName);
            if (product) {
                this.vm.quircAfterSubmittingAnalysis.filter(x => x.QuircProfileName === item.quircProfileName).forEach(ele => {
                    ele.decisionName = item.decisionName;
                    ele.sumUnitLoading = item.sumUnitLoading;
                    ele.sumPercentageLoading = item.sumPercentageLoading;
                    ele.productReferences = item.productReferences;
                });
            }
        });
    }

    excludeProducts(): Promise<void> {
        return new Promise(async (resolve) => {
            const useSemiUnderwritten = this.vm.submitQuestionaireResult.PolicyReferences.some(r => r.Title.toLocaleLowerCase().indexOf('semi') >= 0);
            let excludedProduct: Array<string>;

            if (useSemiUnderwritten) {
                excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(x => x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') === -1).map(x => x.MendixProductUID);
            } else {
                excludedProduct = this.vm.quircAfterSubmittingAnalysis.filter(x => x.decisionName === AppConsts.QuircDecisionName.Excluded || x.decisionName === AppConsts.QuircDecisionName.Postpone || x.MendixProductName.toLowerCase().indexOf('semi') >= 0).map(x => x.MendixProductUID);
            }

            const policyInput = {
                AnsweredQuestions: [],
                CedantId: this.vm.CedantId,
                CustomerReferenceid: this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: this.vm.currentPolicyId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1'
            };

            const policyResult = await this.quirkService.createOrUpdatePolicy(policyInput, true).toPromise();
            const selfEmployedQuestion = policyResult.PolicyDetail.AnsweredQuestions.find(x => x.ExternalReferenceId === AppConsts.QuirckExtQuestionId.IsSelfEmployed);
            const isSelfEmployed = selfEmployedQuestion.Value === '1';

            if (!isSelfEmployed) {
                const selfEmployedProduct = this.vm.quircAfterSubmittingAnalysis
                    .filter(q => q.MendixProductDisplayName === AppConsts.configProducts.SelfEmployedEnhancer || q.MendixProductDisplayName === AppConsts.configProducts.AlternateSelfEmployedEnhancer)
                    .map(x => x.MendixProductUID);

                if (selfEmployedProduct.length > 0) {
                    excludedProduct.push(...selfEmployedProduct);
                }
            }

            this.vm.productDetailsFromFactory
                .forEach(x => x.isIncludedFromQuirc = true);

            this.vm.productDetailsFromFactory
                .filter(x => excludedProduct.some(g => g === x.UID))
                .forEach(x => x.isIncludedFromQuirc = false);

            this.vm.productDetailsFromFactory
                .filter(x => x.isIncludedFromQuirc)
                .forEach(rider => {
                    if (!rider.AssociatedProducts) {
                        return;
                    }

                    rider.AssociatedProducts.filter(x => excludedProduct.some(g => g === x.UID)).forEach(x => x.isIncludedFromQuirc = false);
                });

            if (this.vm.quotePolicyCouples && this.vm.quotePolicyCouples.length > 0 && excludedProduct.length > 0) {
                const deleteObservables: Array<Observable<any>> = [];
                const getQuoteObservables: Array<Observable<QuoteModule.IQuoteDetails>> = [];
                const productsToDelete: Array<string> = [];

                for (const couple of this.vm.quotePolicyCouples) {
                    if (!couple.quote.ProductQuoteds) {
                        continue;
                    }

                    for (const product of couple.quote.ProductQuoteds) {
                        if (excludedProduct.includes(product.ProductConfig.UID)) {
                            if (productsToDelete.includes(product.ProductConfig.UID)) {
                                continue;
                            }

                            deleteObservables.push(this.productService.deleteRiderProduct(couple.quote.UID, product.ProductConfig.UID));
                            getQuoteObservables.push(this.productService.getAQuote(couple.quote.UID));

                            if (product.IsMainProduct) {
                                productsToDelete.push(...couple.quote.ProductQuoteds.map(q => q.ProductConfig.UID));
                            } else {
                                productsToDelete.push(product.ProductConfig.UID);
                            }
                        }
                    }
                }

                if (deleteObservables.length === 0) {
                    resolve();
                    return;
                }

                forkJoin(deleteObservables).subscribe(() => {
                    forkJoin(getQuoteObservables).subscribe(quoteResult => {
                        for (const quote of quoteResult) {
                            this.updateQuoteInPolicyCouple(quote);
                        }

                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    getCommissionForQuoteGuid(commissions: Array<CommissionModule.BrokerCommission>, quoteGuid: string): CommissionModule.BrokerCommission {
        if (!commissions) {
            return {
                commissionValues: [],
                primaryCommission: [],
                secondaryCommission: [],
                commissionPattern: null
            };
        }

        const commission = commissions
            .filter(c => c.commissionValues)
            .find(c => c.commissionValues.length > 0 && c.commissionValues[0].quoteGuid === quoteGuid);

        if (commission) {
            return commission;
        }

        return {
            commissionValues: [],
            primaryCommission: [],
            secondaryCommission: [],
            commissionPattern: null
        };
    }

    expireQuotes(quoteUids: Array<string>): void {
        const quoteObservables = quoteUids.map(q => this.quirkService.CreateOrUpdateQuote({ QuoteStatus: 'Expired' }, q));

        forkJoin(quoteObservables).subscribe(
            (result) => {
                console.log('Expired quote ', result);
            },
            (err) => {
                console.error(err);
            });
    }

    getInceptionDate(): moment.Moment {
        if (this.vm.isQuickQuote) {
            return moment().add(1, 'month').startOf('month');
        }

        return moment();
    }

    async startQuoteJourney(): Promise<Array<{
        extId: string;
        questions: GetQuestionariesModule.IQuestion[];
    }>> {
        const self = this;

        function getQuoteDetails(quotes: Array<GetAllQuotes>, updateQuote: boolean = true, updateInceptionDate: boolean = false, isQuickQuoteToFullQuote: boolean = false): Promise<Array<QuoteModule.IQuoteDetails>> {
            return new Promise((resolve, reject) => {
                const quoteObservables: Array<Observable<QuoteModule.IQuoteDetails>> = quotes.map(q => self.productService.getAQuote(q.QuoteUID));

                if (quoteObservables.length === 0) {
                    resolve([]);
                    return;
                }

                forkJoin(quoteObservables).subscribe(
                    (result) => {
                        let inceptionDate = self.getInceptionDate();
                        let updateQuoteObservables: any;
                        if (isQuickQuoteToFullQuote) {
                            updateQuoteObservables = result
                                .map(r => self.CreateQuote({
                                    Broker: self.vm.brokerGuid,
                                    InceptionDate: inceptionDate.format('yyyy-MM-DD')
                                }, r.UID));
                        } else if (updateInceptionDate || result.some(r => moment(r.InceptionDate).isSameOrBefore(inceptionDate))) {
                            updateQuoteObservables = result
                                .filter(r =>
                                    !r.Person_Broker ||
                                    r.Person_Broker.Identifier !== self.vm.brokerGuid ||
                                    !moment(r.InceptionDate).isSameOrAfter(inceptionDate, 'day')
                                )
                                .map(r => self.CreateQuote({
                                    Broker: self.vm.brokerGuid,
                                    InceptionDate: inceptionDate.format('yyyy-MM-DD')
                                }, r.UID));
                        } else {
                            updateQuoteObservables = result
                                .filter(r =>
                                    !r.Person_Broker ||
                                    r.Person_Broker.Identifier !== self.vm.brokerGuid
                                )
                                .map(r => self.CreateQuote({
                                    Broker: self.vm.brokerGuid
                                }, r.UID));
                        }

                        if (updateQuoteObservables.length === 0 || !updateQuote) {
                            resolve(result);
                            return;
                        }

                        forkJoin(updateQuoteObservables).subscribe(
                            () => {
                                resolve(getQuoteDetails(quotes));
                            },
                            (err) => {
                                reject(err);
                            });
                    },
                    (err) => {
                        reject(err);
                    });
            });
        }

        function getDateDiffFromQuote(quote: GetAllQuotes): number {
            const createdDate = moment.utc(quote.DateCreated);
            const now = moment(new Date());
            const diff = now.diff(createdDate, 'minutes');
            return diff;
        }

        function getQuotePolicyCouple(): { quote: QuoteModule.IQuoteDetails, policy: PolicyModule.IPolicyListResult } {
            const submittedPolicy = self.vm.quotePolicyCouples.find(q => Boolean(q.policy) && self.policyService.isSubmitted(q.policy.PolicyStatusName));

            if (submittedPolicy) {
                return submittedPolicy;
            }

            const nonSubmittedPolicy = self.vm.quotePolicyCouples.find(q => Boolean(q.policy));

            if (nonSubmittedPolicy) {
                return nonSubmittedPolicy;
            }

            return self.vm.quotePolicyCouples[0];
        }

        function setUpdatedQuestion(policy: SubmitQuestionnaireModule.SubmitQuestionnaire): void {
            const multiAnswers = policy.QuestionAnswers.filter(q => q.AllowMultiAnswers);
            const singleAnswers = policy.QuestionAnswers.filter(q => !q.AllowMultiAnswers);
            const answersToAdd = [];

            for (const answer of multiAnswers) {
                const answersForQuestion = multiAnswers.filter(m => m.QuestionId === answer.QuestionId);

                if (!answersToAdd.some(a => a.QuestionId === answer.QuestionId)) {
                    const mergedAnswer = answersForQuestion[0];

                    mergedAnswer.MultipleAnswers = answersForQuestion.map(a => {
                        return {
                            AnswerId: a.AnswerId,
                            AnswerProfileId: a.AnswerProfileId,
                            Text: a.AnswerText,
                            Value: a.AnswerValue
                        };
                    });

                    answersToAdd.push(mergedAnswer);
                }
            }

            answersToAdd.push(...singleAnswers);
            policy.PolicyDetail.AnsweredQuestions = answersToAdd;

            for (const answer of policy.PolicyDetail.AnsweredQuestions) {
                answer.Value = answer.AnswerValue;
                answer.Text = answer.Value;
            }

            self.quirkService.cachedAllQuestionResponse.set('UpdatedQuestion', policy);
        }

        return new Promise((resolve, reject) => {
            forkJoin([
                this.quirkService.getPolicies(this.vm.entityId),
                this.productService.getQuoteOnAPersonBasedOnRole(this.vm.entityId, AppConsts.QuoteRoles.Life_Insured),
                this.productService.getQuoteOnAPersonBasedOnRole(this.vm.entityId, AppConsts.QuoteRoles.Premium_Payer),
                this.productService.getMendixProductAndQuircMapping(),
                this.policyService.getPoliciesForLifeAssured(this.vm.entityId, this.permission.user.access_token)
            ]).subscribe(
                (result) => {
                    const resumePeriod = +abp.setting.values['App.ResumePeriod'];
                    const policies = result[0] || [];
                    const lifeInsuredQuotes = result[1] || [];
                    const premiumPayerQuotes = result[2] || [];
                    const notAcceptedQuotes = lifeInsuredQuotes
                        .concat(premiumPayerQuotes)
                        .filter((value, index, array) =>
                            array.findIndex(a => a.QuoteUID === value.QuoteUID) === index &&
                            value.QuoteStatus === AppConsts.QuoteStatus.Not_Accepted
                        );

                    this.vm.quircAfterSubmittingAnalysis = result[3];
                    this.vm.quircAfterSubmittingAnalysis.forEach(q => {
                        q.QuircProfileName = q.QuircProfileName.trim();
                    });
                    self.vm.lifeInsuredPolicies = result[4] || [];
                    this.clearLoadings();
                    const quotes = notAcceptedQuotes.filter(q => getDateDiffFromQuote(q) <= resumePeriod);
                    const quotesToExpire = notAcceptedQuotes.filter(q => getDateDiffFromQuote(q) > resumePeriod);
                    const acceptedQuotes = lifeInsuredQuotes.filter(q => q.QuoteStatus === AppConsts.QuoteStatus.Accepted);

                    if (quotesToExpire.length > 0) {
                        this.expireQuotes(quotesToExpire.map(q => q.QuoteUID));
                    }

                    if (quotes.length === 0) {
                        let inceptionDate = this.getInceptionDate();

                        this.CreateQuote({
                            MainMember: this.vm.entityId,
                            LifeAssured: this.vm.entityId,
                            LifeAssuredAssuredType: 'Primary_member',
                            InceptionDate: inceptionDate.format('yyyy-MM-DD'),
                            Broker: this.vm.brokerGuid
                        }).subscribe(
                            (result) => {
                                if (this.vm.isQuickQuote) {
                                    resolve(this.startQuoteJourney());
                                    return;
                                }

                                this.createOrUpdatePolicyAndGetAnswers(result, 'Personal Info', false).subscribe((result) => {
                                    resolve(this.startQuoteJourney());
                                    return;
                                });
                            },
                            (err) => {
                                reject(err);
                            });
                    } else {
                        Promise.all([
                            getQuoteDetails(quotes),
                            getQuoteDetails(acceptedQuotes, false),
                        ]).then(async (result) => {
                            this.vm.quotePolicyCouples = this.getQuotePolicyCouples(result[0], policies);
                            this.vm.acceptedQuotePolicyCouples = this.getQuotePolicyCouples(result[1], policies);

                            let quotePolicyCouple = getQuotePolicyCouple();
                            this.vm.quoteId = quotePolicyCouple.quote.UID;

                            if (quotePolicyCouple.policy) {
                                this.vm.currentPolicyId = quotePolicyCouple.policy.ExternalReferenceId;
                                this.quirkService.policyId = quotePolicyCouple.policy.PolicyId;
                            } else {
                                this.vm.currentPolicyId = null;
                                this.quirkService.policyId = null;
                            }

                            if (!quotePolicyCouple.policy || !this.policyService.isSubmitted(quotePolicyCouple.policy.PolicyStatusName)) {
                                let updatedQuotes: any;
                                if (result[0].some(q => moment(q.InceptionDate).date() === 1)) {
                                    updatedQuotes = getQuoteDetails(quotes, true, true, true);
                                } else {
                                    updatedQuotes = getQuoteDetails(quotes, true, true);
                                }
                                updatedQuotes.then(result => {
                                    result.forEach(quote => {
                                        this.updateQuoteInPolicyCouple(quote);
                                    });
                                    if (this.vm.isQuickQuote) {
                                        resolve([getCreatePolicyResult()]);
                                        return;
                                    } else {
                                        this.createOrUpdatePolicyAndGetAnswers(null, 'Personal Info', false).subscribe(
                                            (result) => {
                                                resolve(result);
                                            },
                                            (err) => {
                                                reject(err);
                                            });
                                    }
                                });
                            } else {
                                this.vm.isQuickQuote = false;

                                this.quirkService.getPolicySchedule(quotePolicyCouple.policy.CustomerReferenceId, quotePolicyCouple.policy.ExternalReferenceId)
                                    .subscribe(
                                        (result) => {
                                            this.vm.submitQuestionaireResult = result;
                                            this.vm.isImprovingQuoteNeccesary = true;
                                            setUpdatedQuestion(result);
                                            this.setLoadings();

                                            this.createPerson(() => {
                                                this.policyService.updatePersonAsync(this.vm.entityId).then(() => {
                                                    this.getProductAsync().then(() => {
                                                        this.excludeProducts().then(() => {
                                                            resolve(null);
                                                            return;
                                                        });
                                                    });
                                                });
                                            }, false);
                                        },
                                        (err) => {
                                            reject(err);
                                        });
                            }
                        })
                            .catch(err => {
                                reject(err);
                            });
                    }
                },
                (err) => {
                    reject(err);
                });
        });
    }

    additiveunderwritingloading(productId) {
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(x => x.MendixProductUID === productId);

        if (this.vm.isQuickQuote) {
            return quircProduct.AggregationCategory === 'Life Protection' && this.vm.currentProductOccupationMuliplier.lifeProtectionValue ?
            (this.vm.currentProductOccupationMuliplier.lifeProtectionValue / 1000) / 12 : 0;
        }

        return (quircProduct.sumUnitLoading / 1000) / 12;
    }

    multipicativeunderwritingloading(productId) {
        const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(x => x.MendixProductUID === productId);

        if (this.vm.isQuickQuote) {
            return quircProduct.AggregationCategory === 'Severe Illness Protection' && this.vm.currentProductOccupationMuliplier.severeIllnessValue ?
            (this.vm.currentProductOccupationMuliplier.severeIllnessValue / 100) + 1 : 1;
        }

        return (quircProduct.sumPercentageLoading / 100) + 1;
    }

    pushToProductSummary(product: QuoteModule.IProductQuoted, factoryProduct: Product, quote: QuoteModule.IQuoteDetails): void {
        this.vm.quotedProductDetails.push({
            assuredValue: product.AssuredValue,
            premiumAmount: product.PremiumValue,
            productId: product.ProductConfig.UID,
            productName: product.ProductConfig.Name,
            productedQuotedId: product.ProductConfig.UID,
            quoteId: quote.UID,
            isMainProduct: product.IsMainProduct,
            productCategory: factoryProduct.Category
        });

        factoryProduct.CurrentAssuredValue = product.AssuredValue;
        factoryProduct.CurrentBenefitPattern = factoryProduct.BenefitPatternSet.find(b => b.UID === product.BenefitPattern.UID);
        factoryProduct.CurrentPremiumPattern = factoryProduct.PremiumPatternSet.find(p => p.UID === product.PremiumPattern.UID);
        factoryProduct.currentPremiumValue = product.PremiumValue;

        if (product.IsMainProduct) {
            const associatedUIDs = quote.ProductQuoteds.filter(p => !p.IsMainProduct).map(p => p.ProductConfig.UID);

            this.vm.productSummary.push({
                categoryName: factoryProduct.Category,
                productUID: product.ProductConfig.UID,
                products: [factoryProduct, ...factoryProduct.AssociatedProducts.filter(a => associatedUIDs.some(p => p === a.UID))]
            });
        }
    }

    async createQuotesForMedicallyReferred(): Promise<void> {
        const brokerGuid = this.vm.brokerGuid;

        return new Promise((resolve, reject) => {
            const referredCouple = this.vm.quotePolicyCouples.find(c => c.policy && c.policy.PolicyStatusName === 'Referred');

            if (!referredCouple) {
                resolve();
                return;
            }

            const quoteCountToCreate = +abp.setting.values['App.CloneCount'] - this.vm.quotePolicyCouples.length;

            if (quoteCountToCreate === 0) {
                resolve();
                return;
            }

            const createQuoteObservable: Array<Observable<string>> = [];
            const inceptionDate = this.getInceptionDate();

            for (let i = 0; i < quoteCountToCreate; i++) {
                createQuoteObservable.push(this.CreateQuote({
                    MainMember: this.vm.entityId,
                    LifeAssured: this.vm.entityId,
                    LifeAssuredAssuredType: 'Primary_member',
                    InceptionDate: inceptionDate.format('yyyy-MM-DD'),
                    Broker: brokerGuid
                }));
            }

            forkJoin(createQuoteObservable).subscribe(
                (result) => {
                    const quoteObservables = result.map(r => this.productService.getAQuote(r));

                    forkJoin(quoteObservables).subscribe(
                        (result) => {
                            this.vm.quotePolicyCouples.push(...result.map(r => ({ quote: r, policy: undefined, commission: undefined, currentValues: [] })));
                            resolve();
                        },
                        (err) => {
                            reject(err);
                        });
                },
                (err) => {
                    reject(err);
                });
        });
    }

    clonePoliciesOnResume(): Promise<void> {
        return new Promise((resolve, reject) => {
            const nonSubmittedCouples = this.vm.quotePolicyCouples.filter(c => !c.policy || !this.policyService.isSubmitted(c.policy.PolicyStatusName));
            const submittedCouple = this.vm.quotePolicyCouples.find(c => c.policy && this.policyService.isSubmitted(c.policy.PolicyStatusName));

            if (nonSubmittedCouples.length === 0 || !submittedCouple) {
                resolve();
                return;
            }

            this.getPolicyToClone()
                .then((result) => {
                    const policyObservables: Array<Observable<CreateOrUpdatePolicyDtoOutpuModel.ICreateOrUpdatePolicyDto>> = [];

                    for (const couple of nonSubmittedCouples) {
                        const request: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers = {
                            CedantId: this.CEDANT_ID,
                            CustomerReferenceid: this.vm.entityId,
                            DisplayAnswers: 'true',
                            PolicyReferenceId: couple.quote.UID,
                            ProcessDisclosures: 'true',
                            ShowErrorDetails: '1',
                            AnsweredQuestions: result
                        };

                        policyObservables.push(this.quirkService.createOrUpdatePolicy(request, false));
                    }

                    if (policyObservables.length === 0) {
                        resolve();
                        return;
                    }

                    let policyObservablesLoopCount = 0;

                    policyObservables.forEach(o => o.subscribe(x => {
                            if (policyObservablesLoopCount === policyObservables.length - 1) {
                                const submitObservables: Array<Observable<SubmitQuestionnaireModule.SubmitQuestionnaire>> = [];

                                for (const couple of nonSubmittedCouples) {
                                    submitObservables.push(this.quirkService.submitQuestionnaire(undefined, {
                                        CustomerReferenceid: this.vm.entityId,
                                        PolicyReferenceId: couple.quote.UID
                                    }));
                                }

                                let submitObservablesLoopCount = 0;

                                submitObservables.forEach(o => o.subscribe(x => {
                                    if (submitObservablesLoopCount === submitObservables.length - 1) {
                                        this.quirkService.getPolicies(this.vm.entityId).subscribe(
                                            (result) => {
                                                this.vm.quotePolicyCouples = this.getQuotePolicyCouples(this.vm.quotePolicyCouples.map(c => c.quote), result);
                                                resolve();
                                            },
                                            (err) => {
                                                reject(err);
                                            });
                                    }
                                    submitObservablesLoopCount++;
                                }));

                            }
                            policyObservablesLoopCount++;
                        }
                    ));
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    populateProducts(): Promise<boolean> {
        const self = this;
        this.vm.quotedProductDetails = [];
        this.vm.productSummary = [];

        function setUpProduct(product: QuoteModule.IProductQuoted, factoryProduct: Product, quoteId: string, mainProductId: string = null, isAccelerator: boolean = false, mainProductCurrentSumAssured: number = null): Promise<{ quoteId: string, productId: string, assuredValue: number }> {
            return new Promise(async (resolve, reject) => {
                factoryProduct.IsProductQuoted = true;
                let excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(x => x.excluded !== null);
                let excludedProductSubCategory = excludedProducts.map(x => x.excluded).includes(factoryProduct.DisplayName) ? factoryProduct.DisplayName : '';
                let inceptionDate = self.getInceptionDate();
                inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                let birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                let age = inceptionDate.diff(birthDate, 'years').toString();
                if (!factoryProduct.RateTable) {
                    const maxSumAssured = await self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured, isAccelerator: false, productSubCategory: excludedProductSubCategory });
                    await factoryProduct.getRateTableAsync(self.injector, factoryProduct.MinBenefitAmount, maxSumAssured, null, age);
                }

                self.getMaxSumAssured(factoryProduct.UID, factoryProduct.OriginalMaxBenefitAmount, { mainProductCurrentSumAssured, isAccelerator, productSubCategory: excludedProductSubCategory }).then((result) => {
                    const sortedRateTable = factoryProduct.RateTable
                        .map(r => r.sumAssuredLimit)
                        .filter((val, index, arr) => arr.indexOf(val) === index && val <= factoryProduct.MaxBenefitAmount && val >= factoryProduct.MinBenefitAmount)
                        .sort((a, b) => a - b);

                    if (sortedRateTable.length === 0) {
                        self.productService.deleteRiderProduct(quoteId, factoryProduct.UID).subscribe(result => {
                            self.productService.getAQuote(quoteId).subscribe(result => {
                                factoryProduct.IsProductQuoted = false;
                                self.updateQuoteInPolicyCouple(result);
                                self.vm.isMinimumBenefitNotMet = true;
                                resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: null });
                            });
                        });
                    } else {
                        if (result < product.AssuredValue) {
                            product.AssuredValue = sortedRateTable.filter(s => s <= result)[sortedRateTable.filter(s => s <= result).length - 1];
                        } else if (!sortedRateTable.some(s => s >= product.AssuredValue)) {
                            product.AssuredValue = sortedRateTable.filter(s => s < product.AssuredValue)[sortedRateTable.filter(s => s < product.AssuredValue).length - 1];
                        }

                        const update: ProductQuote = {
                            AdditiveUnderwritingLoading: self.additiveunderwritingloading(product.ProductConfig.UID),
                            AssuredValue: product.AssuredValue,
                            BenefitPattern: product.BenefitPattern.UID,
                            EventSet: product.EventSet.ShortCode,
                            MultiplicativeUnderwritingLoading: self.multipicativeunderwritingloading(product.ProductConfig.UID),
                            PremiumPattern: product.PremiumPattern.UID,
                            Product: product.ProductConfig.UID,
                        };

                        let quoteObservable: Observable<any>;

                        if (!product.IsMainProduct) {
                            (update as RiderProductQuote).MainProduct = mainProductId;
                            quoteObservable = self.productService.createOrUpdateRiderProduct((update as RiderProductQuote), quoteId, mainProductId, product.ProductConfig.UID);
                        } else {
                            quoteObservable = self.productService.productQuoted(quoteId, update, product.ProductConfig.UID, 'PUT');
                        }

                        quoteObservable.subscribe(
                            () => {
                                resolve({ quoteId: quoteId, productId: product.ProductConfig.UID, assuredValue: product.AssuredValue });
                            },
                            (err) => {
                                reject(err);
                            });
                    }
                });
            });
        }

        return new Promise((resolve, reject) => {
            const mainProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const riderProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const boosterProductPromises: Array<Promise<{ quoteId: string, productId: string, assuredValue: number }>> = [];
            const associatedProductFactories = self.vm.productDetailsFromFactory.map(p => p.AssociatedProducts).customFlat();

            for (const couple of this.vm.quotePolicyCouples) {
                if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                    continue;
                }

                const mainProduct = couple.quote.ProductQuoteds.find(p => p.IsMainProduct);
                const mainProductFactory = this.vm.productDetailsFromFactory.find(p => p.UID === mainProduct.ProductConfig.UID);
                mainProductPromises.push(setUpProduct(mainProduct, mainProductFactory, couple.quote.UID));
            }

            if (mainProductPromises.length === 0) {
                resolve(true);
                return;
            }

            Promise.all(mainProductPromises)
                .then((resultMain) => {
                    const boostersToAdd: Array<{ associatedProduct: AssociatedProduct, quoteProduct: QuoteModule.IProductQuoted }> = [];

                    for (const couple of this.vm.quotePolicyCouples) {
                        if (!couple.quote.ProductQuoteds || couple.quote.ProductQuoteds.length === 0) {
                            continue;
                        }

                        let riderProducts = couple.quote.ProductQuoteds.filter(p => !p.IsMainProduct && p.PremiumValue > 0);

                        for (const riderProduct of riderProducts) {
                            const riderProductFactory = associatedProductFactories.find(p => p.UID === riderProduct.ProductConfig.UID) as AssociatedProduct;

                            if (riderProductFactory) {
                                if (riderProductFactory.IsParentDependent) {
                                    boostersToAdd.push({ associatedProduct: riderProductFactory, quoteProduct: riderProduct });
                                    continue;
                                }

                                const mainProductAssuredValue = resultMain.find(r => r.quoteId === couple.quote.UID).assuredValue;
                                const mainProductUid = couple.quote.ProductQuoteds.find(p => p.IsMainProduct).ProductConfig.UID;
                                riderProductPromises.push(setUpProduct(riderProduct, riderProductFactory, couple.quote.UID, mainProductUid, riderProductFactory.Accelerator, mainProductAssuredValue));
                            }
                        }
                    }

                    if (riderProductPromises.length === 0) {
                        riderProductPromises.push(new Promise((resolve) => { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                    }

                    Promise.all(riderProductPromises)
                        .then(async (resultRiders) => {
                            for (const boosterToAdd of boostersToAdd) {
                                let parent = resultMain.find(r => r.productId === boosterToAdd.associatedProduct.DependentParentGuid);

                                if (!parent) {
                                    parent = resultRiders.find(r => r.productId === boosterToAdd.associatedProduct.DependentParentGuid);
                                }

                                if (parent) {
                                    let quoteId = parent.quoteId;

                                    const mainProductId = this.vm.quotePolicyCouples
                                        .map(q => q.quote)
                                        .find(q => q.UID === quoteId).ProductQuoteds
                                        .find(p => p.IsMainProduct).ProductConfig.UID;

                                    boosterProductPromises.push(setUpProduct(boosterToAdd.quoteProduct, boosterToAdd.associatedProduct, quoteId, mainProductId));
                                } else {
                                    let quoteId = self.vm.quotePolicyCouples
                                        .map(c => c.quote)
                                        .find(q => q.ProductQuoteds && q.ProductQuoteds.find(p => p.ProductConfig.UID === boosterToAdd.associatedProduct.UID))
                                        .UID;

                                    await self.productService.deleteRiderProduct(quoteId, boosterToAdd.associatedProduct.UID).toPromise();
                                }
                            }

                            if (boosterProductPromises.length === 0) {
                                boosterProductPromises.push(new Promise((resolve) => { resolve({ assuredValue: null, productId: null, quoteId: null }); }));
                            }

                            Promise.all(boosterProductPromises)
                                .then(_ => {
                                    const quoteObservables = this.vm.quotePolicyCouples.map(q => self.productService.getAQuote(q.quote.UID));

                                    forkJoin(quoteObservables).subscribe(
                                        (quoteResult) => {
                                            for (const couple of this.vm.quotePolicyCouples) {
                                                this.updateQuoteInPolicyCouple(couple.quote);
                                                couple.quote = quoteResult.find(r => r.UID === couple.quote.UID);

                                                if (!couple.quote.ProductQuoteds) {
                                                    continue;
                                                }

                                                for (const product of couple.quote.ProductQuoteds) {
                                                    let productFactory: Product;

                                                    if (product.IsMainProduct) {
                                                        productFactory = this.vm.productDetailsFromFactory.find(p => p.UID === product.ProductConfig.UID);
                                                    } else {
                                                        productFactory = associatedProductFactories.find(p => p.UID === product.ProductConfig.UID);
                                                    }

                                                    this.pushToProductSummary(product, productFactory, couple.quote);
                                                }
                                            }

                                            resolve(true);
                                        },
                                        (err) => {
                                            reject(err);
                                        });
                                }).catch(err => {
                                    reject(err);
                                });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    CreateQuote(createQuote: CreateQuote, quoteId?: string): Observable<string> {
        return this.quirkService.CreateOrUpdateQuote(createQuote, quoteId)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrUpdatePolicyAndGetAnswers(policyId, sectionName, isCached = true): Observable<{ extId: string, questions: GetQuestionariesModule.IQuestion[] }[]> {
        return this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            DisplayAnswers: 'true',
            PolicyReferenceId: policyId || this.vm.quoteId,
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, isCached).pipe(map(res => {
            this.vm.currentPolicyId = res.PolicyDetail.PolicyReferenceId;
            this.vm.quoteId = res.PolicyDetail.PolicyReferenceId;
            return this.quirkService.processGetQuestionForStepper(res.PolicyDetail, sectionName);
        }));
    }

    getBenefitPatternSetList(uidString: string): Observable<BenefitModule.IBenefitModel> {
        return this.productService.getBenefitPatternSet(uidString)
            .pipe(map(res => {
                return res;
            }));
    }

    getPremiumPatternSetList(uidString: string): Observable<PremiumPatternModule.IPremiumPatternModel> {
        return this.productService.getPremiumPatternSet(uidString)
            .pipe(map(res => {
                return res;
            }));
    }

    processRateTable(res: RateTableModule.IRateTableModel[]): RateTableModule.IRateTableModel[] {
        return res.filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('SocioEconomicClass') && y.Description === 'Class_1'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('OccupationCategory') && y.Description === 'Class_1'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('SmokingStatus') && y.Description === 'NonSmoker'))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('Gender') && y.Description === this.policyService.personRatingFactor.gender))
            .filter(x => x.RatingFactors.some(y => y.ShortCode.startsWith('Age') && y.Description === this.policyService.personRatingFactor.age.toString()));
    }


    postProductQuoted(quoteId: string, productQuoteData: ProductQuote, productQuotedId: string): Observable<any> {
        const productQuoteId = this.productQuoteDictionary.get(quoteId);
        let requestMethod = productQuoteId ? 'put' : 'post';
        return this._productService.productQuoted(quoteId, productQuoteData, productQuoteId, requestMethod)
            .pipe(map(res => {
                if (res) {
                    this.productQuoteDictionary.set(quoteId, res.GUID);
                }
                return res;
            }));
    }

    async getProductAsync() {
        let self = this;
        const allowedDisabilityProductNames = await getAllowedDisabilityProductNames();

        const isTestUser = true;

        let productFactory = await new ProductFactory(this.injector)
            .withMetadataAsync(abp.setting.values['ActiveGenerationName'], await this.permission.isSecuritasBroker(), allowedDisabilityProductNames, isTestUser);

        let mainProductFullyUIDList = productFactory
            .extractFulllyMainProductUIDList();

        let mainProductSemiUIDList = productFactory
            .extractSemiMainProductUIDList();

        let allProductUIDList = productFactory
            .extractAllProductUIDList();

        let automaticRiderUIDList = productFactory
            .extractAutomaticRiderUIDList();

        let parentAndChildUIDList = productFactory
            .extractParentAndChildUIDList(automaticRiderUIDList);

        await deleteExcludedProducts(mainProductFullyUIDList, mainProductSemiUIDList, self, allProductUIDList, automaticRiderUIDList, parentAndChildUIDList);

        this.vm.productSyncedOrNot = false;
        this.vm.productDetailsFromFactory = [];
        this.vm.needsAssessmentSumAssuredLevels = await this.productService.getNeedsAssessmentSumAssuredLevels().toPromise();

        if (mainProductSemiUIDList.length > 0) {
            await populateProductDetailsFromFactory(mainProductSemiUIDList, true);
        } else {
            await populateProductDetailsFromFactory(mainProductFullyUIDList, false);
        }

        async function getAllowedDisabilityProductNames(): Promise<Array<string>> {
            const allowedDisabilityProductNames: Array<string> = [];

            if (self.vm.userDetails.age >= 60) {
                return [];
            }

            const url = abp.setting.values['PortalSettings.OccupationDisabilityExclusionsUrl'];

            const occupationsDisabilityExclusions = await self.httpMethodBaseService.get<{
                allowAll: Array<string>,
                allowDisabilityCapitalProtection: Array<string>,
                allowDisabilityIncomeProtection: Array<string>,
                allow7Day30Day: Array<string>,
                allow90Day: Array<string>
            }>(url).toPromise();

            if (occupationsDisabilityExclusions.allowAll.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('disability', 'occupation', '7day', '30day', '90day', 'employed');
                return allowedDisabilityProductNames;
            }

            if (occupationsDisabilityExclusions.allowDisabilityCapitalProtection.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('capital', 'occupation');
            }

            if (occupationsDisabilityExclusions.allowDisabilityIncomeProtection.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('income', 'employed', '7day', '30day', '90day');
                return allowedDisabilityProductNames;
            }

            if (occupationsDisabilityExclusions.allow7Day30Day.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('7day', '30day', 'employed', 'family');
            }

            if (occupationsDisabilityExclusions.allow90Day.includes(self.vm.userDetails.occupation)) {
                allowedDisabilityProductNames.push('90day');
            }

            return allowedDisabilityProductNames;
        }

        async function deleteExcludedProducts(fullyUidList: Array<string>, semiUidList: Array<string>, self: MyCommandCenterService, allProductUidList: Array<string>, automaticRiderUidList: Array<string>, parentAndChildUIDList: Map<string, string[]>): Promise<void> {
            const allGuids = fullyUidList.concat(semiUidList);
            const quotedProductGuids = (self.vm.quotePolicyCouples
                .filter(q => q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0)
                .map(q => q.quote.ProductQuoteds)
                .customFlat() as QuoteModule.IProductQuoted[])
                .filter(p => p.IsMainProduct)
                .map(p => p.ProductConfig.UID);

            const excludedGuids = quotedProductGuids.filter(q => !allGuids.includes(q));
            const removeProductPromises: Array<Promise<any>> = [];
            const getQuotePromises: Array<Observable<QuoteModule.IQuoteDetails>> = [];

            const allQuotedProductGuids = (self.vm.quotePolicyCouples
                .filter(q => q.quote && q.quote.ProductQuoteds && q.quote.ProductQuoteds.length > 0)
                .map(q => q.quote.ProductQuoteds)
                .customFlat() as QuoteModule.IProductQuoted[])
                .filter(p => !automaticRiderUidList.includes(p.ProductConfig.UID))
                .map(p => p.ProductConfig.UID);

            const riderExcludedGuids = allQuotedProductGuids.filter(q => !allProductUidList.includes(q));
            let allExcludedGuids = excludedGuids.concat(riderExcludedGuids);

            let ridersAlreadyRemovedDueToMainProduct: string[] = [];

            for (const [key, value] of parentAndChildUIDList.entries()) {
                value.forEach(x => {
                    if (allExcludedGuids.includes(x) && allExcludedGuids.includes(key)) {
                        ridersAlreadyRemovedDueToMainProduct.push(x);
                    }
                });
            }

            allExcludedGuids = allExcludedGuids.filter(x => !ridersAlreadyRemovedDueToMainProduct.includes(x));

            for (const excludedGuid of allExcludedGuids.filter((v, i, a) => a.indexOf(v) === i)) {
                const quoteUid = self.vm.quotePolicyCouples
                    .map(q => q.quote)
                    .filter(q => q.ProductQuoteds)
                    .find(q => q.ProductQuoteds.map(q => q.ProductConfig.UID).includes(excludedGuid))
                    .UID;

                removeProductPromises.push(self.productService.deleteRiderProduct(quoteUid, excludedGuid).toPromise());
                getQuotePromises.push(self.productService.getAQuote(quoteUid));
            }

            if (removeProductPromises.length > 0) {
                await Promise.all(removeProductPromises);
                const getResults = await Promise.all(getQuotePromises.map(q => q.toPromise()));

                for (const getResult of getResults) {
                    self.updateQuoteInPolicyCouple(getResult);
                }
            }
        }

        async function populateProductDetailsFromFactory(productUids: Array<string>, useSemi: boolean): Promise<{}> {
            return new Promise((resolve) => {
                let iterationsNeeded = productUids.length;
                let excludedProducts = self.vm.needsAssessmentSumAssuredLevels.filter(x => x.excluded !== null);

                for (let mainProduct of productUids) {
                    buildProduct(mainProduct, allowedDisabilityProductNames).then(async (product) => {
                        let setPatterns = false;

                        if ((useSemi && product.Name.toLocaleLowerCase().includes('semi')) || (!useSemi && !product.Name.toLocaleLowerCase().includes('semi'))) {
                            setPatterns = true;
                        }

                        let excludedProductSubCategory = excludedProducts.map(x => x.excluded).includes(product.DisplayName) ? product.DisplayName : '';
                        const maxSumAssured = await self.getMaxSumAssured(product.UID, product.MaxBenefitAmount, { isAccelerator: false, skipExistingCover: true, productSubCategory: excludedProductSubCategory });
                        let upperLimit: number;
                        let sumAssuredRateToAdd: number | null = null;

                        const quircCategory = self.vm.quircAfterSubmittingAnalysis.find(q => q.MendixProductUID === mainProduct).QuircProfileName;
                        const needsAssessmentSumAssuredLevel = self.vm.needsAssessmentSumAssuredLevels.find(n => n.quircCategory === quircCategory);

                        excludedProducts.forEach(element => {
                            if (product.DisplayName !== element.excluded) {
                                // If client can have sum assured higher than no-needs-assessment sum assured, get rates up to
                                // no-needs-assessment sum assured and get rates for sum assured limit = full-needs-assessment
                                // sum assured and infer rates between no-needs-assessment and full-needs-assessment.
                                if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured && maxSumAssured <= needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured) {
                                    sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.partialNeedsAssessmentSumAssured;
                                    upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                } else if (maxSumAssured > needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured) {
                                    sumAssuredRateToAdd = needsAssessmentSumAssuredLevel.fullNeedsAssessmentSumAssured;
                                    upperLimit = needsAssessmentSumAssuredLevel.noNeedsAssessmentSumAssured;
                                } else {
                                    upperLimit = maxSumAssured;
                                }
                            } else {
                                upperLimit = maxSumAssured;
                            }
                        });

                        if (upperLimit < product.MinBenefitAmount) {
                            iterationsNeeded--;

                            if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                notifyProductsLoaded();
                                resolve({});
                            }
                        } else {
                            if (setPatterns) {
                                let inceptionDate = self.getInceptionDate();
                                inceptionDate = inceptionDate.isSameOrBefore(moment(self.vm.quotePolicyCouples[0].quote.InceptionDate)) ? moment(self.vm.quotePolicyCouples[0].quote.InceptionDate) : inceptionDate;
                                let birthDate = self.vm.userDetails.identityNumber.getDateOfBirthByIdNumber();
                                let age = inceptionDate.diff(birthDate, 'years').toString();
                                product.getRateTableAsync(self.injector, product.MinBenefitAmount, upperLimit, sumAssuredRateToAdd, age).then(() => {
                                    product.setDefaultBenefitPattern();
                                    product.setDefaultPremiumPattern();
                                    self.vm.productDetailsFromFactory.push(product);

                                    if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                        notifyProductsLoaded();
                                        resolve({});
                                    }
                                });
                            } else {
                                self.vm.productDetailsFromFactory.push(product);

                                if (self.vm.productDetailsFromFactory.length === iterationsNeeded) {
                                    notifyProductsLoaded();
                                    resolve({});
                                }
                            }
                        }
                    });
                }
            });
        }

        function notifyProductsLoaded(): void {
            self.vm.productSyncedOrNot = true;
            self.vm.producSpinnerEmitter.next(true);
        }

        async function buildProduct(mainProductUID, allowedDisabilityProductNames: Array<string>): Promise<Product> {
            let copy = Object.assign(Object.create(Object.getPrototypeOf(productFactory)), productFactory);

            copy
                .forProduct(mainProductUID)
                .extractUID()
                .extractName()
                .extractCategory()
                .extractDisplayName()
                .extractEventSet()
                .extractMaxBenefitAmount()
                .extractMinBenefitAmount()
                .excludeRiders(allowedDisabilityProductNames);

            let benefitPatternSetPromise = copy
                .extractBenefitPatternSetAsync();

            let premiumPatternSetPromise = copy
                .extractPremiumPatternSetAsync();

            await Promise.all([benefitPatternSetPromise, premiumPatternSetPromise]);
            await copy
                .extractAssociatedProductsAsync();

            let product = copy
                .build();

            return Promise.resolve(product);
        }
    }

    getPolicyToClone(): Promise<any> {
        return new Promise((resolve, reject) => {
            let request: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers = {};
            request = {
                CedantId: this.CEDANT_ID,
                CustomerReferenceid: this.vm.entityId,
                DisplayAnswers: 'true',
                PolicyReferenceId: this.vm.quoteId,
                ProcessDisclosures: 'true',
                ShowErrorDetails: '1',
                AnsweredQuestions: []
            };

            this.quirkService.createOrUpdatePolicy(request, true).subscribe(
                (result) => {
                    try {
                        const answeredQuestions: Array<CreateOrUpdatePolicyInputModule.IAnsweredQuestion> = [];

                        for (const answer of result.PolicyDetail.AnsweredQuestions) {
                            if (!this.quirkService.checkQuestionPresent(answer.ExternalReferenceId)) {
                                continue;
                            }

                            const isNumber = this.quirkService.getQuestionByExtReferenceId([answer.ExternalReferenceId])[0].Answer[0].DataType;

                            if (answer.MultipleAnswers && answer.MultipleAnswers.length > 0) {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    MultipleAnswers: answer.MultipleAnswers
                                });
                            } else {
                                answeredQuestions.push({
                                    ExternalReferenceId: answer.ExternalReferenceId,
                                    Value: isNumber === 'Number' ? +answer.Value : answer.Value
                                });
                            }
                        }

                        resolve(answeredQuestions);
                    } catch (e) {
                        reject(e);
                    }
                },
                (err) => {
                    reject(err);
                });
        });
    }

    checkIfStepHasQuestions() {
        let section: GetQuestionariesModule.ISection;

        section = {
            ExternalReferenceId: AppConsts.ParentSteps.Additionalinfo,
            Questions: []
        };

        this.submitQuestionAnswers(section, this.vm.currentPolicyId, true)
            .subscribe(res => {
                this.vm.isImprovingQuoteNeccesary = res.length === 0;
            });
    }


    getFormattedText(item: string): string[] {
        if (item) {

            let strArr = [];
            item = item.replace('  ', '');

            AppConsts.disclaimerText.StartingAndEndingsOfEveryParagraph.forEach(res => {
                if (item.indexOf(res.substring(0, getIndex(res))) > -1 && item.indexOf(res.substring(getIndex(res) + 2)) > -1) {
                    let items = item.substr(item.indexOf(res.substring(0, getIndex(res))), item.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    let item2 = item.substr(item.indexOf(res.substring(0, getIndex(res))), items.indexOf(res.substr(getIndex(res)).replace('##', '')) + (res.substr(getIndex(res)).replace('##', '').length));
                    strArr.push(item2);
                }

            });

            if (strArr.length === 0) {
                return [item];
            }
            return strArr;
        }

        function getIndex(item: string) {
            return item.indexOf('##');
        }
    }

    createPerson(callback?, showMessage: boolean = true) {
        let person: PersonModule.IPersonInput = {
            Education: this.vm.userDetails.education,
            EffectiveDate: new Date(),
            GrossIncome: this.vm.userDetails.grossSalary,
            IDP_GUID: this.vm.entityId,
            SmokingStatusPUT: this.vm.userDetails.smokingStatus === 'Smoker' ? 'Smoker' : 'Non_Smoker',
            OccupationCategoryPUT: defaultOccupationClass(this.vm.userDetails.occupationCategory) || 'Class_1',
            SocioEconomicClassPUT: this.vm.userDetails.socialEconomic,
            PersonAPI: {
                ContactNumber: this.vm.userDetails.cellNumber,
                Email: this.vm.userDetails.email,
                DateOfBirth: this.vm.userDetails.identityNumber.getDateOfBirthByIdNumber(),
                FirstName: this.vm.userDetails.name,
                Surname: this.vm.userDetails.surname,
                Gender: this.vm.userDetails.identityNumber.getGenderFromIdNumber(),
                IdentificationType: 'IDNumber',
                IDNumber: this.vm.userDetails.identityNumber,
                Initials: this.vm.userDetails.name.substr(0, 1)
            }
        };

        this.policyService.createOrEditPerson(person, this.vm.entityId)
            .subscribe(res => {
                if (showMessage) {
                    abp.notify.success('personal details updated');
                }

                if (callback) { return callback(); }
            });
    }

    getQuoteFlowIsImprovingNeeded() {
        this.quirkService.createOrUpdatePolicy({
            AnsweredQuestions: [],
            CedantId: this.CEDANT_ID,
            CustomerReferenceid: this.vm.entityId,
            PolicyReferenceId: this.vm.quoteId,
            DisplayAnswers: 'true',
            ProcessDisclosures: 'true',
            ShowErrorDetails: '1'
        }, true).subscribe(res => {
            let result = res.PolicyDetail.AnsweredQuestions.find(x => x.ExternalReferenceId === AppConsts.DND_Questions.DND_sales_channel);
            if (result) {
                this.vm.isImprovingQuoteNeccesary = result.Value !== 1;
            }
        });
    }

    changeTemplatePopup(item, item1, forceRed: boolean = false) {

        let iconDiv = document.getElementsByClassName('swal2-icon-show');
        if (iconDiv && iconDiv[0].classList.contains('swal2-icon-show')) {
            iconDiv[0].innerHTML = '';
            (iconDiv[0] as any).style = 'display: none;';
            iconDiv[0].classList.remove('swal2-icon-show');

            let imageDiv = document.getElementsByClassName('swal2-image');

            if (imageDiv) {
                (imageDiv[0] as any).style = '';
                imageDiv[0].classList.add('swal2-icon');
                (imageDiv[0] as any).src  = `src="../../../../assets/common/images/location_exclamation${forceRed ? '_red' : ''}.svg`;
                imageDiv[0].classList.remove('swal2-image');
            }
        }

        if (item !== undefined) {
            (document.getElementsByClassName('swal2-confirm')[0] as any).innerText = item;
            document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
            document.getElementsByClassName('swal2-confirm')[0].classList.add('btn-primary');

            if (forceRed) {
                document.getElementsByClassName('swal2-confirm')[0].classList.add('bg-cust-portal-red');
            }
        } else {
            document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
        }

        if (item1 !== undefined) {
            (document.getElementsByClassName('swal2-cancel')[0] as any).innerText = item1;
            document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
            document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
        } else {
            document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
        }
    }

    sendPolicyReferencesEmail(): void {
        const self = this;

        function getStatus(submitResult: SubmitQuestionnaireModule.SubmitQuestionnaire): string {
            if (self.isQuestionnaireReferred(submitResult)) {
                return 'Medical Referred';
            }

            if (submitResult.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred) {
                return 'HIV Referred';
            }

            return submitResult.PolicyStatusName;
        }

        const couple = this.vm.quotePolicyCouples.find(c => c.policy && c.policy.ExternalReferenceId && c.policy.ExternalReferenceId === this.vm.currentPolicyId);

        this.policyService.queuePolicyReferencesEmail(
            this.vm.currentPolicyId,
            this.vm.submitQuestionaireResult,
            getStatus(this.vm.submitQuestionaireResult),
            couple.quote.Person_LifeAssured.Identifier,
            couple.quote.Person_MainMember.Identifier,
            this.permission.user.profile.sub
        ).subscribe(() => {
        }, (err) => {
            console.error(err);
        });
    }

    async getQuoteBrokerConsultantsNames(): Promise<string[]> {
        let bdmsList = [];
        let broker = await this.policyService.getBroker(this.vm.quotePolicyCouples[0].quote.Person_Broker.Identifier).toPromise();

        if (broker) {
            let bcs = await this.leadService.getConsultantsForBroker(broker.BrokerIDP_GUID, this.authservice.user.access_token).toPromise();

                if (bcs && bcs.length > 0) {
                    bcs.forEach(x => {
                        bdmsList.push(`${x.givenName} ${x.familyName}`);
                    });
                }
        }

        return bdmsList;
    }

    private showExclusionPopups(): Promise<void> {
        this.vm.productExclusionMap = [];

        for (const product of this.vm.submitQuestionaireResult.Products) {
            const exclusions: Array<string> = product.ProductReferences.filter(r => r.TypeName === 'Exclusions').map(e => e.Reference);

            if (exclusions.length === 0) {
                continue;
            }

            const quircProduct = this.vm.quircAfterSubmittingAnalysis.find(q => q.QuircProfileName === product.ProfileName);

            const descriptionCategory = product.ProfileName === 'Impairment Cover' ? 'Disability Capital Protection' : product.ProfileName.includes('Income Protection') ? 'Disability Income Protection' : quircProduct.MendixProductCategory;

            const existingProductExclusionMap = this.vm.productExclusionMap.find(p => p.descriptionCategory === descriptionCategory);

            if (existingProductExclusionMap) {
                existingProductExclusionMap.exclusions.push(...exclusions);
                existingProductExclusionMap.exclusions = existingProductExclusionMap.exclusions.filter((v, i, a) => a.indexOf(v) === i);
            } else {
                this.vm.productExclusionMap.push({
                    descriptionCategory: descriptionCategory,
                    productCategory: quircProduct.MendixProductCategory,
                    exclusions: exclusions.filter((v, i, a) => a.indexOf(v) === i),
                    fullDescriptionCategory: `Principal ${descriptionCategory}`,
                });
            }
        }

        let countOfDisabilityProducts = this.vm.productDetailsFromFactory.map(x => x.Category).filter(y => y === 'Disability Protection').length;

        if (countOfDisabilityProducts === 1) {
            this.vm.productExclusionMap = this.vm.productExclusionMap.filter(x => x.descriptionCategory !== 'Disability Capital Protection');
        }

        if (this.vm.submitQuestionaireResult.Products.find(pr => pr.DecisionName === 'Included' && pr.ProfileName !== 'Life Cover')) {
            let avaiableProductCategories = this.vm.productDetailsFromFactory.map(x => x.Category).filter((v, i, a) => a.indexOf(v) === i);

            let productExclusionMap = this.vm.productExclusionMap.filter(x => avaiableProductCategories.includes(x.productCategory));

            let exlusionPopupContent = '';

            for (const productExclusion of productExclusionMap) {
                exlusionPopupContent += '<b>' + productExclusion.descriptionCategory + '</b><br>';

                for (const exclusion of productExclusion.exclusions) {
                    exlusionPopupContent += exclusion + '<br><br>';
                }
            }

            exlusionPopupContent = 'In addition to the General Exclusions that apply to all Elevate policies, the following additional individual exclusions will apply to your policies:<br><br>' + exlusionPopupContent;
            const message = abp.message.info(exlusionPopupContent, 'INDIVIDUAL EXCLUSIONS WILL APPLY', { isHtml: true });
            this.changeTemplatePopup('CONTINUE', undefined);
            return message;
        }
    }

    isReferredOrDeclined() {
        return this.isQuestionnaireReferred(this.vm.submitQuestionaireResult) ||
            this.isQuestionnaireDeclined(this.vm.submitQuestionaireResult) ||
            this.vm.userDetails.manualRefer;
    }

    isCoverApproved() {
        return !this.isReferredOrDeclined() && !this.has7DayReferral();
    }

    async policyStatusChecks() {
        this.vm.isReferred = this.isReferredOrDeclined() || this.has7DayReferral();

        this.vm.show7DayPopups = !this.isReferredOrDeclined() && (this.vm.show7DayPopups || this.has7DayReferral());

        if (this.isQuestionaireExcluded(this.vm.submitQuestionaireResult)) {
            await this.showExclusionPopups();
        }

        if (this.vm.isMinimumBenefitNotMet) {
            await this.showMinimumBenefitNotMetModal();
        }

        switch (this.vm.isReferred) {
            case true:
                if (this.is7DayStatus(AppConsts.Referral7DayStatuses.NotApproved)) {
                    break;
                }

                if (this.is7DayRequestLocked()) {
                    break;
                }

                if (this.vm.show7DayPopups) {
                    this.show7DayAddedPopup();
                }

                break;
            case false:
                if (this.is7DayStatus(AppConsts.Referral7DayStatuses.NotApproved)) {
                    break;
                }

                if (this.is7DayRequestLocked()) {
                    break;
                }

                if (this.vm.show7DayPopups) {
                    this.show7DayRemovedPopup();
                    this.vm.show7DayPopups = false;
                }

                break;
        }
    }

    public is7DayProduct(product: Product): boolean {
        return product.RiderPolicy !== true && (product.EventSet.toLowerCase().includes('7day') || product.Name.toLowerCase().includes('7day'));
    }

    public has7DayProduct(): boolean {
        const primaryProducts: Array<Product> = [];

        for (const productSummary of this.vm.productSummary) {
            const products = productSummary.products.filter(rec => rec.RiderPolicy !== true); //use !== true because of undefined values
            primaryProducts.push(...products);
        }

        return primaryProducts.some(rec => this.is7DayProduct(rec));
    }

    public get7DayProducts(): Array<Product> {
        const primaryProducts: Array<Product> = [];

        for (const productSummary of this.vm.productSummary) {
            const products = productSummary.products.filter(rec => rec.RiderPolicy !== true); //use !== true because of undefined values
            primaryProducts.push(...products);
        }

        return primaryProducts.filter(rec => this.is7DayProduct(rec));
    }

    public has7DayReferral(): boolean {
        // Does it have a 7 day product?
        const has7DayProduct = this.has7DayProduct();

        // Does it have a 7 day disclosure attached? If not, the product has been recently added and this process needs to start now
        const referralDisclosureStatus = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayStatus);
        const referralDisclosureDate = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayDateRequested);

        if (!referralDisclosureStatus) {
            return has7DayProduct;
        }

        // Is the disclosure expired? If it is, treat it as new
        const isExpired = this.is7DayRequestExpired(referralDisclosureDate.disclosureValue);

        if (isExpired) {
            return has7DayProduct;
        }

        // Is the disclosure in a locked state? If so, it needs to be set to the value of the disclosure and not to the current state of the current products.
        if (this.is7DayRequestLocked()) {
            return referralDisclosureStatus.disclosureValue === AppConsts.Referral7DayStatuses.Requested;
        }

        // Is the disclosure approved?
        const isApproved = referralDisclosureStatus.disclosureValue === AppConsts.Referral7DayStatuses.Approved;

        return has7DayProduct && !isApproved;
    }

    public is7DayRequestExpired(date: string): boolean {
        return moment(date).startOf('day').add(30, 'days').isSameOrBefore(moment().startOf('day'));
    }

    public get7DayStatus(): string {
        const referralDisclosureStatus = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayStatus);

        return !!referralDisclosureStatus ? referralDisclosureStatus.disclosureValue : '';
    }

    public get7DayStatusDate(): string {
        const dateClaim = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayDateRequested);

        return !!dateClaim ? dateClaim.disclosureValue : '';
    }

    public get7DayGroupId(): string {
        const referralDisclosureStatus = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayStatus);

        return !!referralDisclosureStatus ? referralDisclosureStatus.disclosureGroupId : null;
    }

    public is7DayStatus(status: string): boolean {
        const referralDisclosureStatus = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayStatus);

        return !!referralDisclosureStatus && referralDisclosureStatus.disclosureValue === status;
    }

    public is7DayRequestLocked(): boolean {
        const referralDisclosureStatus = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayStatus);
        const referralDisclosureDate = this.vm.userAdditionalDisclosures.find(rec => rec.disclosureType === AppConsts.Referral7DayTypes.Referral7DayDateRequested);

        if (!referralDisclosureStatus || !referralDisclosureDate) {
            return false;
        }

        return moment(referralDisclosureDate.disclosureValue).add(30, 'minutes').isSameOrBefore(moment()) && referralDisclosureStatus.disclosureValue === AppConsts.Referral7DayStatuses.Requested;
    }

    public show7DayAddedPopup() {
        let content = '';

        if (this.vm.isQuickQuote) {
            content = `We currently do not provide automatic underwriting terms for 7-day waiting period benefits.
                       <br><br>
                       If you choose to continue to add this benefit, after you have completed a FULL QUOTE we will refer this application to our underwriting team to review and confirm if 7-day waiting period terms will be provided.
                       <br><br>
                       The standard requirements we will request are:
                       <br>
                        <ul style="width:100%;margin:auto;text-align:left;">
                            <li>Proof of Income (IT34 or acceptable company financials)</li>
                            <li>Occupational Questionnaire</li>
                        </ul>`;
        } else {
            content = `We currently do not provide automatic underwriting terms for 7-day waiting period benefits.
                       <br><br>
                       If you choose to continue to add this benefit, we will refer this application to our underwriting team to review and confirm if 7-day waiting period terms will be provided.
                       <br><br>
                       The standard requirements we will request are:
                       <br>
                        <ul style="width:100%;margin:auto;text-align:left;">
                            <li>Proof of Income (IT34 or acceptable company financials)</li>
                            <li>Occupational Questionnaire</li>
                        </ul>`;
        }

        abp.message.info(content, '7-DAY WAITING PERIOD REQUIREMENTS', { isHtml: true });
        this.changeTemplatePopup('CONTINUE', undefined);
    }

    public show7DayRemovedPopup() {
        let content = '';

        if (this.vm.isQuickQuote) {
            content = `Given that you have removed the 7-Day waiting period benefit that additional underwriting requirements associated with this benefit will no longer be applicable.
                       <br><br>
                       After you complete the FULL QUOTE journey, any requirements will only be based on a standard medical, financial or occupation referral. If you are provided with automatic terms, you can proceed to activation.`;
        } else {
            content = `Given that you have removed the 7-Day waiting period benefit that additional underwriting requirements associated with this benefit are no longer applicable.
                       <br><br>
                       You can go ahead and proceed to activation.`;
        }

        abp.message.info(content, '7-DAY WAITING PERIOD REQUIREMENTS REMOVED', { isHtml: true });
        this.changeTemplatePopup('CONTINUE', undefined);
    }

    setDisclosures() {
        this.vm.isBusinessAssuranceQuote =  (this.vm.coverReason === AppConsts.CoverReasons.BusinessAssurance);

        this.vm.isPolicyHolderNotInsuredLife = (this.vm.policyHolderIsInsuredLife === 'No');
    }

    isQuestionnaireDeclined(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Declined;
    }

    isQuestionnaireReferred(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        return questionnaire.PolicyStatusName === AppConsts.quircSubmittingQuestionAnalysis.PolicyStatusName.Referred && this.vm.submitQuestionaireResult.PolicyReferences.some(p => p.TypeName === 'Refer \/ Decline Reasons');
    }

    isQuestionaireExcluded(questionnaire: SubmitQuestionnaireModule.SubmitQuestionnaire): boolean {
        if (!questionnaire.Products || questionnaire.Products.length === 0) {
            return false;
        }

        return questionnaire.Products.some(p =>
            p.ProductReferences &&
            p.ProductReferences.length > 0 &&
            p.ProductReferences.some(r => r.TypeName === 'Exclusions'));
    }

    showMinimumBenefitNotMetModal(): Promise<void> {
        const message = abp.message.warn('Given your income, one of your previously quoted products has been removed.', 'MINIMUM ASSURED LEVEL NOT MET');
        this.changeTemplatePopup('Ok', undefined);
        return message;
    }

    showReferredModal(): Promise<any> {
        const message = abp.message.info(
            'We cannot confirm your underwriting just yet as we will need additional medical information from your (your client’s) doctor.<br><br>' +
            'An Elevate Customer Service agent will be in contact shortly to outline the next steps.<br><br>' +
            'In the meantime, go ahead and engage with the Elevate Life products on offer.<br><br>' +
            '<b>Please note:</b> premiums presented here are indicative only. Only after we confirm and assess the remaining underwriting information needed we can provide your (your client’s) final premium and any individual policy terms that may be applicable.',
            'MORE INFORMATION NEEDED',
            {
                isHtml: true
            });

        this.changeTemplatePopup('CONTINUE', undefined, true);
        return message;
    }

    showApprovedModal(): Promise<any> {
        const message = abp.message.info(
            'We have assessed the information provided and we have approved your (your client’s) cover.<br><br>' +
            'On this Level 5 you can finalise the configuration of your (your client’s) benefits. Please take note of any individual policy terms (loadings and/or exclusions) that have been applied. You can also find the premium and cover projections, important benefit and premium rules as well as your (your client’s) rights and obligations as an Elevate Life policyholder.<br><br>' +
            'Proceed to Level 6 to complete beneficiary and banking information and then then all policy details can be reviewed on Level 7 where you can activate your (your client’s) cover.<br><br>',
            '<b>Exciting news – you are approved for cover!</b>',
            {
                isHtml: true
            });

        this.changeTemplatePopup('CONTINUE', undefined, false);
        return message;
    }

    private showDeclinedModal(): Promise<void> {
        const popup = abp.message.error(
            'Unfortunately, we aren\'t able to tailor any products to match your needs today :(<br><br>But this shouldn\'t be the case for long!<br><br><a target="_blank" href="https://www.elevate.co.za/how-it-works/"><u>Find out more</u></a>',
            'Policy Declined',
            {
                isHtml: true
            });

        this.changeTemplatePopup('Ok', undefined);
        return popup;
    }

    showBusinessAssuranceModal(): Promise<any> {
        const message = abp.message.info(
            `Given this is noted as a business assurance policy, you will be receiving communication from our Ops Team to guide you on the financial
             underwriting requirements that need to be completed before this policy can be activated.</br></br>
            In addition, ${this.vm.isQuickQuote ? 'when you go through the Full Quote journey you will not be able to proceed past Level 5' :
            'you will not be able to proceed past Level 5 of this Full Quote journey'} as the policies will be activated on your behalf once
             all policy details, requirements and financial underwriting has been completed.`,
            'BUSSINESS ASSURANCE',
            {
                isHtml: true
            });

        this.changeTemplatePopup('CONTINUE', undefined, true);
        return message;
    }

    getProductDescription(product: Product): string {
        if (!product) {
            return '';
        }

        if (product.Category === 'Severe Illness Protection') {
            return 'your client is to suffer a severe illness';
        }

        if (product.Category === 'Disability Protection') {
            if (product.AssociatedProducts.length > 0) {
                return 'your client being unable to work due to illness or disability';
            }

            if (product.DisplayName === 'Principal Disability Capital Protection - Accelerator') {
                return 'your client is to suffer a disability';
            }

            if (product.DisplayName === 'Principal Disability Capital Protection') {
                return 'your client is to suffer a disability';
            }

            return 'your applicant becoming permanently disabled';
        }

        return 'your client\'s passing';
    }

    getStepperTitle(title: string|null|undefined): string {
        if (title) {
            return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
        } else {
            return '';
        }
    }

    setCustomAnswer(question: GetQuestionariesModule.IQuestion) {
        if (['Mental_health_family_history', 'Cancer_family_history'].includes(question.ExternalReferenceId)) {
            question.Answer.forEach(ans => {
                if (ans.Text === 'No') {
                    ans.Text = 'No, not more than 1';
                }

                if (ans.Text === 'Yes') {
                    ans.Text = 'Yes, more than 1';
                }
            });
        }
    }

    monitorFormChanges(name: string, form: FormGroup) {
        this.stepperFormGroup.addControl(name, form);
    }

    resetStepper(matStepper: MatStepper) {
        if (matStepper.steps.length > 0) {
            matStepper.selectedIndex = 0;
            matStepper.steps.forEach(st => {
                    st.completed = false;
            });
        }
    }
}
